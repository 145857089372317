#home {
  position: relative;
  background: url('../../assets/Images/bgIMG.png');
  background-size: cover; //standard for img as bg
  background-repeat: repeat;
  background-position: center;





  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}

.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;

  padding: 11rem 2rem 0;

  //Extra large devices
  @media screen and (min-width: 2000px) {
    padding-top:10rem;
  }

  //Screens smaller than tablet
  @media screen and (max-width: 1366px) {
    padding-top:6rem;
  }

  //Screens smaller than tablet
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  //Mobile
  @media screen and (max-width: 450px) {
    padding: 6rem 1rem 2rem;
  }
}

.app__header-info {
  flex: 0.65;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  padding-top: 2rem;

  margin: 0 2rem;

  //Extra large devices
  @media screen and (max-width: 2000px){
    width: 100%;
  }
}

.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  .badge-cmp, .tag-cmp {
    padding: 1rem 2rem;
    background: var(--steel-color);
    border-radius: 15px;
    flex-direction: row;
    width: auto;
    opacity: 80%;

    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  .tag-cmp {
    flex-direction: column;
    margin-top: 3rem;

    p {
      width: 100%;
      text-transform: uppercase;
      text-align: left;
    }
  }
  span {
    font-size: 2.5rem;

    //Extra large devices
    @media screen and (min-width: 2000px) {
      font-size: 5rem;
    }
  }

  //Screens smaller than tablet
    @media screen and (max-width: 1200px){
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
.app__header-circles {
  flex: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;
  margin-left: 2rem;
  position: relative;

  div {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    opacity: 60%;

    background: var(--steel-color);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    img {
      width: auto;
      height: 80%;
      //object-fit: contain;
    }
  }
  div:nth-child(1) {
    width: 270px;
    height: 270px;
  }

  div:nth-child(2) {
    margin: 1.75rem;
    width: 190px;
    height: 190px;
  }

  div:nth-child(3) {
    width: 150px;
    height: 150px;
  }

  //Extra large devices
  @media screen and (min-width: 2000px) {
    div:nth-child(1) {
      width: 400px;
      height: 400px;
    }

    div:nth-child(2) {
      margin: 1.75rem;
      width: 270px;
      height: 270px;
    }

    div:nth-child(3) {
      width: 200px;
      height: 200px;
    }
  }
  //Screens smaller than tablet
  @media screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;

    div {
      margin: 1rem;
    }
  }
}

.app__header-img {
  flex: 1;
  display: flex;
  flex-direction: column; /* Changed to column */
  place-items: center;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding-top: 0rem;
  //Screens smaller than tablet
  @media screen and (max-width: 1440px) {
    padding-top: 0rem;
  }
  //Screens smaller than tablet
  @media screen and (max-width: 450px) {
    padding-top: 24rem;
  }

  .app__header-decal2 {
    width: 85%;
    height: auto;
    object-fit: contain;
    object-position: top;
    top: 25%;
    //Screens smaller than tablet
    @media screen and (max-width: 450px) {
      visibility: hidden;
    }
  }

  .app__header-memoji {
    height: 600px;
    width: 600px;
    object-fit: contain;
    position: absolute;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    top: 25%; /* Move down by 100 pixels */
    border-radius: 50%;

    //Screens smaller than tablet
    @media screen and (max-width: 2000px) {
      height: 450px;
      width: 450px;
    }

    //Screens smaller than tablet
    @media screen and (max-width: 1536px) {
      height: 400px;
      width: 400px;
    }

    //Screens smaller than tablet
    @media screen and (max-width: 1440px) {
      height: 425px;
      width: 425px;
    }

    //Screens smaller than tablet
    @media screen and (max-width: 1366px) {
      height: 350px;
      width: 350px;
    }

  }

  .app__header-decal {
    width: 85%;
    height: auto;
    object-fit: contain; /* Fit the entire image within the container without distortion */
    position: absolute; /* Position absolutely within the container */
    padding-top: 25%;
    padding-bottom: 1rem;

    //Screens smaller than tablet
    @media screen and (max-width: 1280px) {
      padding-top: 40%;
    }
    //Screens smaller than tablet
    @media screen and (max-width: 1280px) and (max-height: 800px) {
      padding-top: 34%;
    }
    //Screens smaller than tablet
    @media screen and (max-width: 450px) {
      visibility: hidden;
    }
  }

  .app__header-decal-alt {
    visibility: hidden;
    //Screens smaller than tablet
    @media screen and (max-width: 450px) {
      visibility: visible;
      object-fit: contain;
      width: 100%;
      height: auto;
      position: relative; /* Position absolutely within the container */
    }
  }
}


//.app-header-profile-img:first-child {
//  bottom: 0; /* Position the first image at the top */
//}
//
//.app-header-profile-img:last-child {
//  top: 0; /* Position the second image at the bottom */
//}




//    //Extra large devices
//    @media screen and (min-width: 2000px) {
//      height: 900px;
//    }
//
//    //Screens smaller than tablet
//    @media screen and (max-width: 450px) {
//      display: flex;
//      flex-direction: column;
//      place-items: center;
//      margin: 3rem 0rem;
//      height: 550px
//    }
//  }
//  //Screens smaller than tablet
//  @media screen and (max-width: 1200px) {
//    margin: 2rem 0;
//  }
//}