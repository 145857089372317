//.head-text {
//  height: 0px;
//}

.app__weatherAppDemo {
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-content: space-evenly;
}

.app__weatherAppDemo {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 8rem;
  height: 100%;
  width: 100%;

  @media screen and (min-width: 2000px){
    margin-top: 6rem;
  }
}

.app__weatherAppDemo-item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 0rem;
  border-radius: 15px;
  transition: 0.3s ease-in-out;

  //Extra large devices
  @media screen and (min-width: 2000px) {
    width: 70%;
  }

  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  //@media screen and (min-width: 768px) {
  //  height: 100px;
  //}

  ////Small mobile devices
  //@media screen and (max-width: 1500px) {
  //  width: 100%;
  //}
}
.demo-container{
  padding: 1rem;
  background: var(--white-color);
  border-radius: 15px;
  flex-direction: row;
  width: 100%;
  object-fit: contain;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 100%;

  div {
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }

  //Small mobile devices
  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  //Extra large devices
  @media screen and (min-width: 2000px) {
    height: 100%
  }


@media screen and (max-width: 1200px) {
  width: 100%;
  height: 1500px
}

  //Small mobile devices
  @media screen and (max-width: 450px) {
    width: 100%;
    height: 600px;
    padding: 0.5rem;
  }
}

img {
  width: 100%;
  height: 170px;
  border-radius: 15px;
  object-fit: cover; //prevent img skew
  justify-content: center;
  //@media screen and (min-width: 2000px) {
  //  width: 370px;
  //  margin: 2rem 4rem;
  //  height: auto;
  //}

}