.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__profiles {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 2rem;
}

.app__profile-item {
  width: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem;
  color: var(--white-color)
}

img {
  width: 100%;
  height: 170px;
  border-radius: 15px;
  object-fit: cover; //prevent img skew

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;
    height: 320px;
  }
}
