.section__descriptions {
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 2.5rem;

  @media screen and (max-width: 650px){
    grid-template-columns: repeat(2,1fr);
    gap: .5rem;
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: var(--section-overlay);

    border-radius: 10px;
    padding: 0.5rem;

    @media screen and (max-width: 650px){
      padding: 0.5rem;
    }

    .description__card-icon {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 5px;
      margin-bottom: 12px;

      @media screen and (max-width: 1500px){
        margin: 4px 4px auto auto;
      }

      small {
        text-transform: capitalize;

        @media screen and (max-width: 2100px){
          font-size: 10px;
        }
      }

    }

    @media screen and (max-width: 2100px){
      h2 {
        font-size: 18px;
      }
    }

  }
}